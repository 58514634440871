@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
}

a:hover {
  transition: all 0.2s ease-in;
  color: var(--seasonColor);
}

a::-webkit-selection {
  color: var(--seasonColor);
}