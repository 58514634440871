.Schedule-div {
    padding-top: 3.2rem;
    margin: auto;
    min-height: calc(100svh - 6.4rem);
}

.Schedule-iframe {
    width: 100%;
    height: calc(100svh - 6.4rem);
    border: none;
    display: block;
}