.Author-div {
    min-height: calc(100svh - 6.4rem);
    margin: auto;
    max-width: 800px;
    padding-top: 3.2rem;
}

.Author-p {
    font-size: 1.25rem;
    line-height: 1.65;
    padding-top: 1rem;
    color: black;
    text-align: center;
}

.Author-iframe {
    border: 0;
    pointer-events: none;
}