.Home-div {
    min-height: calc(100svh - 3.2rem);
}

.Banner-div {
    background-size: cover;
    background: url("/public/media/background/YogurtOne.jpg");
    color: white;
    display: flex;
    flex-direction: column;
    font-size: x-large;
    height: 50svh;
    justify-content: center;
    background-size: cover;
}

.Bottom-div {
    margin: auto;
    max-width: 800px;
}

.Bottom-figure {
    padding-top: 2rem;
    float: left;
    width: 30%;
    font-size: small;
    margin-right: 2rem;
}

.Bottom-p {
    font-size: 1.25rem;
    line-height: 1.65;
    text-align: justify;
    padding: 1rem;
}

.Bottom-img {
    border-radius: 25px;
    border: 2.5px solid black;
    content: url("/public/media/profile/ShannonOne.jpg");
    display: inline-block;
    height: 90%;
    margin-left: 1rem;
    width: 90%;
}

.Banner-h1 {
    text-shadow: 1px 1px 1px #000;
    font-weight: bolder;
}

.Bottom-figcaption {
    margin-left: 1rem;
}