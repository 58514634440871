.Header-header {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    color: white;
    position: fixed;
    text-align: left;
    z-index: 1;
    height: 3.2rem;
    overflow: hidden;
}

.Header-div {
    transition: all 1.5s;
}

.Home-li,
.Schedule-li,
.Blog-li {
    display: inline-block;
    padding: 1rem 2rem;
}

@media (hover: hover) {

    .Home-li:hover,
    .Schedule-li:hover,
    .Blog-li:hover {
        background: rgba(0, 0, 0, 0.3);
    }
}

.Header-ul {
    text-align: left;
}

.Header-div.darkenHeader {
    background-color: rgba(0, 0, 0, 0.8);
}