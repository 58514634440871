.Disclaimer-div {
    padding-top: 3.2rem;
    margin: auto;
    max-width: 800px;
    min-height: calc(100svh - 6.4rem);
}

.Disclaimer-h1 {
    font-size: 1.25rem;
    font-weight: bold;
}

.Disclaimer-p {
    font-size: medium;
    line-height: 1.65;
    text-align: justify;
    padding: 1rem;
}