.Footer-footer {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    color: white;
    bottom: 0;
    height: 3.2rem;
    overflow: hidden;
}

.Footer-div {
    height: inherit;
}

.Footer-ul>.Pipe-li {
    display: inline-block;
    padding: 1rem 0rem;
}

.Footer-ul>.Disclaimer-li,
.Footer-ul>.Years-li {
    display: inline-block;
    padding: 1rem 2rem;
}

.Footer-ul>.Disclaimer-li>a,
.Footer-ul>.Pipe-li>a,
.Footer-ul>.Years-li>a {
    text-decoration: none;
}

.Years-li,
.Pipe-li {
    pointer-events: none;
}

@media (hover: hover) {
    .Footer-ul>li:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
}