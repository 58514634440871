@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900,400italic,700italic,900italic);

.Blog-div {
    padding-top: 3.2rem;
    margin: auto;
    max-width: 800px;
    min-height: calc(100svh - 6.4rem);
}

.Blog-h1 {
    font-weight: bold;
    padding-bottom: 1rem;
}

.Section-div {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 25px;
}

.Post-div .Section-div {
    width: 75%;
    margin: 0 auto;
}

.Section-h1 {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 400;
    text-align: center;
}

.Section-h1::first-letter {
    font-weight: 500;
}

.Section-p {
    outline-offset: 110px;
    text-align-last: center;
    font-weight: 400;
    line-height: 1.65;
    margin: 5% auto;
}